import { render, staticRenderFns } from "./agency.vue?vue&type=template&id=6a95df54&scoped=true&"
import script from "./agency.vue?vue&type=script&lang=js&"
export * from "./agency.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a95df54",
  null
  
)

export default component.exports